

export interface ViewableModel {
    name : string;
    urn : string;
    selected : boolean;
}

export interface ViewableSet {
    setID : string;
    description? : string;
    token: string;
    expires : string;
    expiration : Date;
    models : ViewableModel[];
}


// Pulls a new token and set infromation
export function getViewableSet(setID : string, password : string, setAvailable :  (set : ViewableSet )=> void) {

    var request = {
        setID: setID, 
        password : password
    };

    var xhr = new XMLHttpRequest();
    xhr.open("POST","https://stantecptsglink.azurewebsites.net/autodeskbridge/viewable");
    xhr.onload = function() {

        var accessData = JSON.parse(xhr.responseText) as ViewableSet;
        accessData.expiration = new Date(accessData.expires);
        setAvailable(accessData);
    }
    xhr.onerror = () : any => {
        console.error("Could not get viewable information from stantec");
    }
    
    xhr.send(JSON.stringify(request));

}






