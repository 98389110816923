import * as sv from './stantec-viewables';
import {SelectorExtension} from './selector-extension';


type setAvailableType = (set : sv.ViewableSet )=> void;

export function createViewer(setID : string, password : string) : StantecForgeViewerLink {
    return new StantecForgeViewerLink(setID,password);
}

class StantecForgeViewerLink {

    private currentSet : sv.ViewableSet;

    public minSecondsRemaining = 300;

    private viewerApp : Autodesk.Viewing.ViewingApplication;

    constructor(private setID : string, private password : string) {

        this.refreshSet = this.refreshSet.bind(this);
        this.getAccessToken = this.getAccessToken.bind(this);
        this.loadDocuments = this.loadDocuments.bind(this);
    }

    public initialize(divID : string) {
        
        var self  = this;

        var opts : Autodesk.Viewing.InitializerOptions = {
            getAccessToken : self.getAccessToken,
            env : "Production",
            api : "derivativeV2",
            
        };

        Autodesk.Viewing.Initializer(opts,()=> {
            self.viewerApp = new Autodesk.Viewing.ViewingApplication(divID);
            self.viewerApp.registerViewer(self.viewerApp.k3D, Autodesk.Viewing.Private.GuiViewer3D, {
                extensions : ['StantecModelSelectorExtension']
            });
            self.getModels(self.loadDocuments);
        });

        

    }

    private loadDocuments(models : sv.ViewableModel[]) {

        var self = this;
        if (!models || models.length == 0) {
            console.error("No viewable models found");
        }

        this.loadViewableModel(models[0]);
    }

    private loadViewableModel(model : sv.ViewableModel) {

        var self = this;
        model.selected = true;
        
        self.viewerApp.loadDocument(

            "urn:" + model.urn, 

            (doc)=> {
                
                var viewables = self.viewerApp.bubble.search({'type':'geometry'});
                if (viewables.length === 0) {
                    console.error('Document contains no viewables.');
                    return;
                }
            
                // Choose any of the avialble viewables
                self.viewerApp.selectItem(
                    viewables[0].data, 
                    ()=>console.log("Loaded model"), 
                    (errorCodes : Autodesk.Viewing.ErrorCodes, errorMsg : string) => console.error('onItemLoadFail() - errorMsg:' + errorMsg)
                    );
                }
            , 
            (viewerErrorCode : string)=> {
                console.error('onDocumentLoadFailure() - errorCode:' + viewerErrorCode);
                }
            );

    }


    public getModels(onGetModels : (models : sv.ViewableModel[])=>void) {

        this.getSet(workSet=> {
            onGetModels(workSet.models)
        })

    }

    public getAccessToken(onGetAccessToken : (token : string, expireTimeInSeconds : number)=>void) {

        var self = this;
        this.getSet(workSet => {

            let expireTimeInSeconds = self.getSecondsRemaining();
            let token = workSet.token;

            onGetAccessToken(token,expireTimeInSeconds);

        })
    }
    private getSet(setAvailable : setAvailableType) {

        if (
            !this.currentSet || 
            this.getSecondsRemaining() < this.minSecondsRemaining
            ) {
            this.refreshSet(setAvailable);
            return;
        }

        setAvailable(this.currentSet);
    }

    private getSecondsRemaining() {
        if (!this.currentSet)
            return -1;

        return (this.currentSet.expiration.valueOf() - (new Date()).getUTCDate().valueOf()) / 1000;
    }

    // Pulls a new token and set infromation
    private refreshSet(setAvailable : setAvailableType) {

        var self = this;
        sv.getViewableSet(this.setID,this.password,(v)=> {
            SelectorExtension.viewables = v;
            SelectorExtension.modelSelected = (vw)=>self.loadViewableModel(vw);
            self.currentSet = v;
            setAvailable(v);

        })
     

    }



}


