import * as React from 'react';
import * as vs from './stantec-viewables';
import {ModelEntry} from './model-entry';


export interface  ISelectorProps  {
    viewableSet : vs.ViewableSet;
    modelSelected : (model : vs.ViewableModel)=>void;
}


export  class ModelSelector extends React.Component<ISelectorProps,{}> {

    constructor(props : ISelectorProps) {
        super(props);
    
      }

      public render(): React.ReactElement<ISelectorProps> {

        return (
            <div>
              {this.props.viewableSet.models.map(m=> 
                <ModelEntry 
                          key={m.name} 
                          name={m.name} 
                          isSelected={m.selected} 
                          selected={()=>{this.props.modelSelected(m)}} 
                          />
              )}
            </div>


        )
      }
}
        
    

