import * as React from 'react';
import * as ReactDom from 'react-dom';
import {ModelSelector, ISelectorProps} from './model-selector'
import * as vs from './stantec-viewables';
import * as styles from './styles.css';

export class SelectorPanel extends Autodesk.Viewing.UI.DockingPanel {

    constructor(private parentContainer : HTMLElement, public id : string,title : string, 
        private viewables : vs.ViewableSet,
        private modelSelected: (model : vs.ViewableModel)=>void
        ) {


        super(parentContainer,id,title, {shadow : true});
  
        this.id = id;
        this.container.style.top = "0px";
        this.container.style.left = "0px";
    
        this.container.style.width = "200px";
        this.container.style.height = "400px";
    
        this.container.style.resize = "auto";

        this.initialize = this.initialize.bind(this);

        var rootElem = document.createElement('div');
        rootElem.id = this.id + "PanelContentID";
        rootElem.className = styles.stnPanelContent;
        this.content = rootElem;
        this.container.appendChild(this.content);
        
       this.refresh();
    
    

    }
    public refresh() {

        const element: React.ReactElement<ISelectorProps > = React.createElement(
            ModelSelector,
            {
                viewableSet: this.viewables,
                modelSelected : this.modelSelected
            }
          );

          
          ReactDom.render(element,this.content as HTMLElement);
        
    }
    

}