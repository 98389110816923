
import {SelectorPanel} from './selector-panel';
import * as vs from './stantec-viewables';
import * as styles from './styles.css'




export class SelectorExtension extends Autodesk.Viewing.Extension {

    public viewer : Autodesk.Viewing.Private.GuiViewer3D;
    private panel : SelectorPanel = null;

    static viewables : vs.ViewableSet = null;
    static modelSelected : (model : vs.ViewableModel)=> void;

    constructor(viewer : Autodesk.Viewing.Private.GuiViewer3D, 
      options : Autodesk.Viewing.ViewingApplicationOptions,
      
     ) {
        super(viewer,options);
    }

    public load() : boolean {

        var ctrlGroup = this.getControlGroup();

        this.createControls(ctrlGroup);
    
       var panelID = "p" + (new Date()).valueOf();

       var viewerElem = this.viewer.container as HTMLElement;
  
       if (SelectorExtension.viewables) {
       let viewTitle = "Stantec Models";
      
       if (SelectorExtension.viewables.description)
           viewTitle = SelectorExtension.viewables.description;

       this.panel = new SelectorPanel(viewerElem,panelID,viewTitle,SelectorExtension.viewables,
        (model : vs.ViewableModel)=> {
            SelectorExtension.viewables.models.forEach(m=>m.selected = false);
          model.selected = true;
            SelectorExtension.modelSelected(model);
            this.panel.refresh();
          }
        );
       }
      
        console.log("Loaded Stantec Extension");
        return true;

    }

    public unload() : boolean {
        console.log("Unloaded Stantec Extension");
        return true;

    }

    public getControlGroup() : Autodesk.Viewing.UI.ControlGroup {

        var toolbar = null;
    
        try {
          toolbar = this.viewer.getToolbar(true);
    
         // if(!toolbar) {
           // toolbar = createDivToolbar();
         // }
        }
        catch (ex) {
            //toolbar = createDivToolbar();
        }
    
        if (!toolbar) {
            alert("no toolbar");
            return null;
        }
        var control = toolbar.getControl(
          'Autodesk.ADN.Gallery.ControlGroup');
    
        if(!control) {
    
          control = new Autodesk.Viewing.UI.ControlGroup(
            'Autodesk.ADN.UIComponent.ControlGroup');
    
          toolbar.addControl(control, {index : 0});
        }
    
        return control;
      }
    

      
  private createControls(parentGroup) {
    var self = this;
    var btn = createButton(
      'Autodesk.ADN.UIComponent.Button.Show',
    
      styles.stnModelButton,
      'Show Available Models',
      ()=>{
        console.log("Showing Panel");
        self.panel.setVisible(true);
      });

    parentGroup.addControl(btn);
  }
}



function createButton(id : string, className : string, tooltip : string, handler : (e : Event)=>void) {

    var button = new Autodesk.Viewing.UI.Button(id);
    button.setIcon(className);
    button.setToolTip(tooltip);
    button.onClick = handler;
    return button;
  }

