import * as React from 'react';
import * as styles from './styles.css';


export interface  IModelEntryProps  {
    name : string;
    isSelected : boolean;
    selected : ()=>void;
}


export  class ModelEntry extends React.Component<IModelEntryProps,{}> {

    constructor(props : IModelEntryProps) {
        super(props);
    
      }

      public render(): React.ReactElement<IModelEntryProps> {

        return (
            <div onClick={this.props.selected} className={styles.stnModelEntry}>
              <div className={this.props.isSelected ? styles.stnModelEntrySelected : null}>
                {this.props.name}
              </div>
            </div>
        )
      }
}
        
    

